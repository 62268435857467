import { CommonEnvironment } from './environment.common';

export class Environment extends CommonEnvironment {
  static contact: any = {
    email: '',
    custom: '<p>For spørsmål, ring Seniorkultur på 90 19 27 14 innen fristen.</p><p>For mer informasjon, besøk oss på <a target="_blank" href="https://www.trondheim.kommune.no/spaserstokken/" style="color: blue;">trondheim.kommune.no/spaserstokken/</a></p>',
    phone: '90 19 27 14'
  };
  static content: any = {
    aboutus: {
      en: '',
      nb: '<p>Et godt og tilgjengelig kulturtilbud er viktig gjennom hele livsløpet. Det er et mål at alle seniorer i Trondheim kommune har tilgang til et mangfoldig og variert kulturtilbud. Den kulturelle spaserstokken i Trondheim har flere hundre kulturarrangement årlig, på ulike arenaer i hele byen. Arrangement i Den kulturelle spaserstokken er spesielt tilrettelagt for seniorer, men er åpne for alle interesserte.</p><p>Kultur er også et viktig helsefremmende virkemiddel, som bidrar til sosialt fellesskap og livskvalitet. Den kulturelle spaserstokken tilbyr arrangement på helse- og velferdssentre og nærmiljøarenaer i alle bydeler, og samarbeider med ulike kulturaktører, kulturinstitusjoner og festivaler om arrangement og tilrettelegging.</p><p>Den kulturelle spaserstokken gir tilbud innen alle kunstformer: Musikk, teater, film, litteratur, kunst, turer, og gir i stor grad oppdrag til lokale utøvere.</p><p>Du kan kontakte Seniorkultur på tlf. 90 19 27 14. Vår telefon er åpen på hverdager fra 12.00-15.00.</p>'
    },
    welcomeMessage: {
      en: '',
      nb: '<p>Et godt og tilgjengelig kulturtilbud er viktig gjennom hele livsløpet. Det er et mål at alle seniorer i Trondheim kommune har tilgang til et mangfoldig og variert kulturtilbud. Den kulturelle spaserstokken i Trondheim har flere hundre kulturarrangement årlig, på ulike arenaer i hele byen. Arrangement i Den kulturelle spaserstokken er spesielt tilrettelagt for seniorer, men er åpne for alle interesserte.</p><p>Kultur er også et viktig helsefremmende virkemiddel, som bidrar til sosialt fellesskap og livskvalitet. Den kulturelle spaserstokken tilbyr arrangement på helse- og velferdssentre og nærmiljøarenaer i alle bydeler, og samarbeider med ulike kulturaktører, kulturinstitusjoner og festivaler om arrangement og tilrettelegging.</p><p>Den kulturelle spaserstokken gir tilbud innen alle kunstformer: Musikk, teater, film, litteratur, kunst, turer, og gir i stor grad oppdrag til lokale utøvere.</p><p>Du kan kontakte Seniorkultur på tlf. 90 19 27 14. Vår telefon er åpen på hverdager fra 12.00-15.00.</p>'
    },
    titleFrontpage: {
      en: 'Upcoming events',
      nb: 'Kommende arrangementer'
    },
    siteName: 'Seniorkultur',
    siteNameTranslatable: {
      nb: 'Seniorkultur',
      en: 'Seniorkultur'
    }
  };
  static firebase: any = {
    apiKey: "AIzaSyB4ctWriafcGzhIyG3ZkZu__1Qd9385QxI",
    authDomain: "seniorkultur.no",
    projectId: "senior-kultur",
    storageBucket: "senior-kultur.appspot.com",
    messagingSenderId: "735285113504",
    appId: "1:735285113504:web:8949385b3a317c04edcfc5",
    measurementId: "G-SL05C14RHZ"
  };
  static newsletterEnabled: boolean = false;
  static language: any = {
    multilanguage: false,
    defaultLanguage: 'nb'
  }
  static owner: any = {
    name: "DKSS - Trondheim Kommune",
    link: 'https://www.trondheim.kommune.no/spaserstokken/'
  };
  static partners: any[] = [{
    logo: 'trondheim-kommune-logo.png',
    link: 'https://trondheim.kommune.no/',
    style: 'original'
  }];
  static production: boolean = true;
  static synchro: any[] = [];
  static registration: any = {
    allowPublic: false
  };
  static rsvpOptions: any = {
    defaultSMS: {
      from: 'DKSS',
      supportPhone: '90 19 27 14',
      'change-tickets': 'Dine billetter til EVENT_TITLE på START_DATE ble oppdatert. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til Seniorkultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'change-tickets-active': 'Dine billetter til EVENT_TITLE på START_DATE ble oppdatert. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til Seniorkultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'change-tickets-waiting_list': 'Dine billetter til EVENT_TITLE på START_DATE ble oppdatert. Arrangementet er utsolgt og din bestilling er herved satt på venteliste. Du vil få tilbud om å bli med på dette arrangementet dersom det skulle oppstå ledig plass. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Denne meldingen kan ikke besvares. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen',
      'change-customer-name': 'Dine billetter til EVENT_TITLE på START_DATE ble oppdatert. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'change-customer-address': 'Dine billetter til EVENT_TITLE på START_DATE ble oppdatert. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. CUSTOMER_ADDRESS. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'change-customer-comments': 'Dine billetter til EVENT_TITLE på START_DATE ble oppdatert. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Comments: COMMENTS. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'guest-cancel-booking': 'Dine billetter (TICKETS_DESCRIPTION) til EVENT_TITLE på START_DATE er AVBESTILT. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Hvis du ikke har avbestilt disse billettene, ta kontakt med SeniorKultur på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-cancel-booking': 'Dine billetter (TICKETS_DESCRIPTION) til EVENT_TITLE på START_DATE er AVBESTILT. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Hvis du ikke har avbestilt disse billettene, ta kontakt med SeniorKultur på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-change-venue': 'Dine billetter (TICKETS_DESCRIPTION) til EVENT_TITLE på START_DATE ble oppdatert med et nytt sted: VENUE_NAME. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-change-start-date': 'Dine billetter (TICKETS_DESCRIPTION) til EVENT_TITLE på VENUE_NAME ble oppdatert med en ny dato: START_DATE. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-change-status-active': 'Du har stått på venteliste, men har nå fått plass på arrangementet EVENT_TITLE. Sted: VENUE_NAME. Dato: START_DATE. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du ikke ønsker plass, må du ringe oss på 90 19 27 14 innen fristen eller gå til nettsiden til Seniorkultur. Denne meldingen kan ikke besvares',
      'admin-change-status-waiting_list': 'Arrangementet er utsolgt og din bestilling er herved satt på venteliste. Du vil få tilbud om å bli med på dette arrangementet dersom det skulle oppstå ledig plass. Arrangement: EVENT_TITLE. Sted: VENUE_NAME. Dato: START_DATE. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-change-status-cancelled': 'Dine billetter (TICKETS_DESCRIPTION) til EVENT_TITLE på START_DATE er AVBESTILT. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-change-status-event_cancelled': 'Arrangementet EVENT_TITLE på START_DATE på VENUE_NAME ble AVLYST. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'admin-change-status-attended': ' ',
      'admin-change-status-no_show': ' ',
      'status-active': 'Du har herved fått plass. Arrangement: EVENT_TITLE. Sted: VENUE_NAME. Dato: START_DATE. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til Seniorkultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares',
      'status-waiting_list': 'Arrangementet er utsolgt og din bestilling er herved satt på venteliste. Du vil få tilbud om å bli med på dette arrangementet dersom det skulle oppstå ledig plass. Arrangement: EVENT_TITLE. Sted: VENUE_NAME. Dato: START_DATE. Bestillingsnummer: BOOKING_NUMBER. Navn: CUSTOMER_NAME. Billetter: TICKETS_DESCRIPTION. Beløp: TOTAL_AMOUNT. Dersom du vil avbestille eller endre antall billetter gå til nettsiden til SeniorKultur eller ring oss på 90 19 27 14 innen fristen. Denne meldingen kan ikke besvares'
    }
  }
  static siteURL: string = 'https://seniorkultur.no';
  static town: any = {
    name: 'Trondheim',
    hint: 'Trondheim Norway',
    lat: 63.430133,
    lng: 10.392836
  };
  static ui: any = {
    ...CommonEnvironment.ui,
    defaults: {
      organizerID: "yF77cUlumkbUAnfiJcku", 
      editableBy: "CGRZl4azWNBRejupsnwg"
    },
    labelPrice: {
      nb: 'Pris',
      en: 'Price'
    },
    showAddEventForVisitors: false,
    showOnMetadata: ["categories", "tickets", "facebookURL", "moreInfoURL", "videosURL", "organizers", "email", "phone", "venue"],
    showOnForm: {
      ...CommonEnvironment.ui.showOnForm,
      featuredEvent: true,
      rsvp: true,
      maxCharsTitle: 100,
    },
    showOnFooter: {
      facebook: false,
      aboutus: true,
      contact: true,
      organizers: false,
      privacy: true,
      what: false
    },
    showOnMenu: [
      {
        name_en: 'My events',
        name_nb: 'Mine arrangementer',
        internal: true,
        link: '/my-events',
        icon: 'list',
        loggedIn: true,
        admin: true
      },
      {
        name_en: 'Bookings',
        name_nb: 'Påmelding',
        internal: true,
        link: '/bookings',
        icon: 'local_activity',
        loggedIn: true,
        admin: true
      }, {
        name_en: 'About us',
        name_nb: 'Om oss',
        internal: true,
        link: '/about-us',
        icon: 'info',
        loggedIn: false,
        admin: false
      }, {
        name_en: 'For artists',
        name_nb: 'For artister',
        internal: false,
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSecPfM3rWnA3bet4Lrb-ekwqzdAiDbfWFZcsA6XNMmFTxGukQ/viewform?usp=sf_link',
        icon: 'theater_comedy',
        loggedIn: false,
        admin: false
      },{
        name_en: 'My teams',
        name_nb: 'Mine lag',
        internal: true,
        link: '/my-teams',
        icon: 'group_work',
        loggedIn: true,
        admin: true
      }, {
        name_en: 'Customers',
        name_nb: 'Kunder',
        internal: true,
        link: '/customers',
        icon: 'group',
        loggedIn: true,
        admin: true
      },{
        name_en: 'Account',
        name_nb: 'Konto',
        internal: true,
        link: '/account',
        icon: 'account_circle',
        loggedIn: true,
        admin: true
      },{
        name_en: 'Admin',
        name_nb: 'Admin',
        internal: true,
        link: '/admin',
        icon: 'settings_applications',
        loggedIn: true,
        admin: true
      },{
        name_en: 'Warnings',
        name_nb: 'Advarsler',
        internal: true,
        link: '/warnings',
        icon: 'warning',
        loggedIn: true,
        admin: true
      },
    ],
    showWelcomeMessage: false
  };
};

export const environment: any = Environment;
