import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, zip } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { Event } from '../models/event';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-booking-event-selector',
  templateUrl: './booking-event-selector.component.html',
  styleUrls: ['./booking-event-selector.component.scss']
})
export class BookingEventSelectorComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  eventFormControl: FormControl;

  @Input()
  toucher: Observable<any>;

  @Input()
  filter: any = {
    fromDate: new Date(),
    untilDate: null,
    eventID: null
  };

  @Input()
  showRangeFilter: boolean = true;

  @Input()
  showAllMyEventsOption: boolean = true;

  @Input()
  hint: string = this.translate.instant("Select an event");

  @Output()
  onEventsLoaded: EventEmitter<Event[]> = new EventEmitter<Event[]>();

  @Output()
  onFilterChanged: EventEmitter<any> = new EventEmitter<any>();

  //Events
  events: Event[];
  eventFilterCtrl: FormControl = new FormControl();
  filteredInstances: Observable<any>;

  //Events range
  eventsRange: FormGroup = new FormGroup({
    fromDate: new FormControl(!this.filter?.eventID && this.filter?.fromDate || null),
    untilDate: new FormControl(!this.filter?.eventID && this.filter?.untilDate || null)
  });
  showEventsRange: boolean = false;

  label: string;

  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;

  constructor(
    public translate: TranslateService,
    private eventService: EventService,) { }

  ngOnInit(): void {
    this.fetchAvailableEvents();
    this.sub = this.toucher?.subscribe(() => this.eventFormControl.markAllAsTouched());
    this.sub1 = this.eventFormControl.valueChanges.subscribe((selEvent) => this.label = selEvent?.title_nb);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes?.filter?.currentValue.fromDate != changes?.filter?.previousValue?.fromDate
      || changes?.filter?.currentValue.untilDate != changes?.filter?.previousValue?.untilDate) {
      this.eventsRange.setValue({
        fromDate: changes.filter.currentValue.fromDate,
        untilDate: changes.filter.currentValue.untilDate
      })
    }
  }

  //Events initialization
  fetchAvailableEvents(): void {
    const fromDate = this.eventsRange.get('fromDate').value || null;
    const untilDate = this.eventsRange.get('untilDate').value || null;
    this.sub2?.unsubscribe();
    this.sub2 = this.eventService.getAllEventsWithTicketsAvailable().pipe(
      map((events: Event[]) => this.applyFilter(events, this.filter?.eventID, fromDate, untilDate)),
      //TODO CHECK THIS
      first()      
    )
      .subscribe((evs: Event[]) => this.onEventsFetched(evs));
  }

  applyFilter(events: Event[], eventID: any, fromDate: any, untilDate: any): any {
    if (events?.length > 0) {
      const filteredEvents: Event[] = [];
      for (let event of events) {
        const evID = !eventID || event.id == eventID;
        const fromFilter = (fromDate && ((event.startDate?.seconds * 1000) > fromDate.getTime() || event.repetitions?.some((r) => (r.startDate?.seconds * 1000) > fromDate.getTime()))) || !fromDate;
        const untilFilter = (untilDate && ((event.startDate?.seconds * 1000) < untilDate.getTime() || event.repetitions?.some((r) => (r.startDate?.seconds * 1000) < untilDate.getTime()))) || !untilDate;
        if (evID && fromFilter && untilFilter) {
          let transformedEvent = event;
          if (event.repetitions?.length > 0) {
            if (fromDate) {
              transformedEvent.repetitions = transformedEvent.repetitions?.filter((r) => (r.startDate?.seconds * 1000) > fromDate.getTime());
              if ((transformedEvent.startDate?.seconds * 1000) < fromDate.getTime()) {
                transformedEvent = {...transformedEvent, ...transformedEvent.repetitions.shift() };
              }
            }
            if (untilDate) {
              transformedEvent.repetitions = transformedEvent.repetitions?.filter((r) => (r.startDate?.seconds * 1000) < untilDate.getTime());
            }
          }
          filteredEvents.push(transformedEvent);
        }
      }
      return filteredEvents;
    }
    return [];
  }


  onEventsFetched(events: Event[]): void {
    this.onEventsLoaded.emit(events);
    this.events = events;
    this.filteredInstances = this.eventFilterCtrl.valueChanges
      .pipe(
        startWith<string | Event>(''),
        map(value => typeof value === 'string' ? value : value.title_nb + " " + value.venueObj.name),
        map(searchWord => searchWord.trim() ? this._filterEvents(searchWord) : this.events.slice()),
        map((events: Event[]) => {
          return [].concat.apply([], events.map((event: Event) => {
            if (event.repetitions?.length > 0) {
              return { eventTitle: event.title_nb, instances: [event].concat(event.repetitions.map((r) => ({ ...event, ...r }))) };
            }
            return { eventTitle: event.title_nb, instances: [event] };
          })).sort((e1, e2) => e1.eventTitle >= e2.eventTitle ? 1 : -1);
        })
      );
    this.setupFormListeners();
  }

  instancesComparator(option: Event, value: Event): boolean {
    return option?.id === value?.id && option?.startDate?.seconds == value?.startDate?.seconds && option?.venueObj?.name == value?.venueObj?.name;
  }

  private _filterEvents(searchWord: string): Event[] {
    const filterValue = searchWord.toLowerCase();
    return this.events.filter(option => option.title_nb.toLowerCase()?.indexOf(filterValue) != -1 || option.venueObj?.name?.toLowerCase()?.indexOf(filterValue) != -1);
  }


  private setupFormListeners() {
    //Filter over the events range. This will fetch a new set of bookings
    this.eventsRange.valueChanges.subscribe((newFilter) => {
      this.events = [];
      this.showEventsRange = false;
      this.fetchAvailableEvents();
      this.onFilterChanged.emit(newFilter);
    });
  }

  onlyUpcoming() {
    this.eventsRange.setValue({
      fromDate: new Date(),
      untilDate: null
    });
  }

  allEvents() {
    this.eventsRange.setValue({
      fromDate: null,
      untilDate: null
    });
  }


}
