import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Customer } from '../models/customer';
import { Address } from '../models/venue';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-customer-form-selector',
  templateUrl: './customer-form-selector.component.html',
  styleUrls: ['./customer-form-selector.component.scss']
})
export class CustomerFormSelectorComponent implements OnInit, OnDestroy {

  @Input()
  customerForm: FormGroup;

  @Input()
  toucher: Observable<any>;

  updatedCustomer: Customer = new Customer();

  //Customer autoselect
  allCustomers: Customer[];
  filtreredCustomers: Customer[];
  isLoadingCustomers: boolean = true;
  customerFormControl: FormControl = new FormControl();
  filteredOptions: Observable<Customer[]>;

  phone: string;
  address: string;
  id: string;
  addressCompulsory: boolean = true;
  sub: Subscription;
  sub1: Subscription;

  //SMS, first content param, second content param
  validComponents: boolean[] = [];

  constructor(private customerService: CustomerService) { }

  ngOnInit(): void {
    this.sub = this.customerService.getAllCustomers().subscribe(
      (allCustomers) => {
        this.allCustomers = allCustomers;
        this.filtreredCustomers = allCustomers;
        this.isLoadingCustomers = false;
        this.filteredOptions = this.customerFormControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.name;
            if (name?.length > 0) {
              this.updatedCustomer.name = name;
              this.customerForm.get('name').setValue(name);
            }
            return name ? this._filter(name as string) : this.allCustomers.slice();
          }),
        );
      }
    );
    this.sub1 = this.toucher?.subscribe(() => this.customerFormControl.markAllAsTouched());
  }

  displayFn(customer: Customer): string {
    return customer?.name || '';
  }

  private _filter(name: string): Customer[] {
    return this.allCustomers.filter(option => option.name.toLowerCase().includes(name.toLowerCase()));
  }


  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub1?.unsubscribe();
  }

  //FORM LISTENERS
  onCustomerSelected(event) {
    if (event.option?.value) {
      console.log(event.option.value);
      this.updatedCustomer = event.option;
      this.phone = event.option.value?.phone || null;
      this.address = event.option.value?.address || null;
      this.id = event.option.value?.id || null;
      this.customerForm.get('address').setValue(this.address);
      this.customerForm.get('phone').setValue(this.phone);
      this.customerForm.get('id').setValue(this.id);
    }
  }

  onCustomerPhoneChange(newPhone: number) {
    this.updatedCustomer.phone = newPhone;
    this.customerForm.get('phone').setValue(newPhone);
  }

  onCustomerAddressChange(newAddress: Address) {
    this.updatedCustomer.address = newAddress;
    this.customerForm.get('address').setValue(newAddress);
  }

}
